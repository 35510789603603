import { Role } from './role';

export class User {
  id: number;
  logo?: string;
  company_id?: string;
  company_alias?: string;
  company_logo?: string;
  station_id?: string;
  station_logo?: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  avatar: string;
  role: Role;
  secondary_role?: string;
  token?: string;
  refresh_token?: string;
}
