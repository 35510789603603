import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil, first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { environment } from 'environments/environment';

import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'app/service/company/company.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  apiUrl = environment.apiUrl

  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public submitted = false;
  public returnUrl: string;
  public passwordTextType: boolean;
  public error = '';

  public company

  public loadingLogin = false

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(private _coreConfigService: CoreConfigService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _route: ActivatedRoute,
    private _router: Router) {
    if (this._authenticationService.currentUserValue) {
      this._authenticationService.redirectUser(this._authenticationService.currentUserValue.role)
    }
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.loadingLogin = true;
      this._authenticationService
        .login(this.f.email.value, this.f.password.value)
        .pipe(first())
        .subscribe(
          data => {
            this._authenticationService.redirectUser(data.role)
          },
          error => {
            this.error = error;
            this.loadingLogin = false;
          }
        );
    }
  }

  getCompany(alias) {
    this.companyService.getCompanyByAlias(alias)
      .subscribe({
        next: (data) => {
          this.company = data
        },
        error: (e) => console.error(e)
      });
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    let company = this.route.snapshot.paramMap.get('company');
    if(company){
      this.getCompany(company)
    }

    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required]
    });

    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
