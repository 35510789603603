<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    <button class="btn btn-icon btn-outline-primary mr-1" rippleEffect *ngIf="this.currentUser"
      routerLink="{{ this.currentUser && this.currentUser.role == 'company_admin' ? '/company/order':this.currentUser && this.currentUser.role == 'deliveryman' ? '/company/deliveryman_interface':'/customer/order' }}"><i
        data-feather="home"></i></button>
    <img role="button"
      routerLink="{{ this.currentUser && this.currentUser.role == 'company_admin' ? '/company/order':this.currentUser && this.currentUser.role == 'deliveryman' ? '/company/deliveryman_interface':'' }}"
      *ngIf="company" src="{{ apiUrl }}/uploads/{{company.logo}}" alt="brand-logo" width="36" />
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <!-- <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->
    <!--/ Toggle skin -->

    <!-- Search -->
    <app-navbar-search
      *ngIf="!this.currentUser || this.currentUser.role == 'company_admin' || this.currentUser.role == 'customer'"></app-navbar-search>
    <!--/ Search -->

    <!-- Cart -->
    <app-navbar-cart
      *ngIf="!this.currentUser || this.currentUser.role == 'company_admin' || this.currentUser.role == 'customer'"
      [company]="company"></app-navbar-cart>
    <!--/ Cart -->

    <!-- Notification -->
    <!-- <app-navbar-notification></app-navbar-notification> -->
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li *ngIf="this.currentUser" ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <ng-container>
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder"
              *ngIf="this.currentUser.company_logo || this.currentUser.station_logo">{{ this.currentUser.firstName }} {{
              this.currentUser.lastName
              }}</span><span class="user-status">{{ this.currentUser.role }}</span>
          </div>
          <span class="avatar">
            <img *ngIf="this.currentUser.role === 'customer'" class="round"
              src="assets/images/avatars/customer-avatar.png" alt="avatar" height="40" width="40" />
            <img *ngIf="this.currentUser.role === 'super_admin'" class="round"
              src="{{ apiUrl }}/uploads/{{ this.currentUser.logo }}" alt="avatar" height="40" width="40" />
            <img *ngIf="this.currentUser.company_logo" class="round"
              src="{{ apiUrl }}/uploads/{{ this.currentUser.company_logo }}" alt="avatar" height="40" width="40" />
            <img *ngIf="this.currentUser.station_logo" class="round"
              src="{{ apiUrl }}/uploads/{{ this.currentUser.station_logo }}" alt="avatar" height="40" width="40" />
            <span class="avatar-status-online"></span>
          </span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/company/profile']"><span [data-feather]="'user'" [class]="'mr-50'"></span>
          Profile</a>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span>
          Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>