import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private _refreshCompany = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshCompany() {
    return this._refreshCompany;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/list/company`);
  }

  getCompanyByAlias(alias) {
    return this._httpClient.get(`${environment.apiUrl}/api/company_by_alias/${alias}`);
  }

  getCompany(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/company/${id}`);
  }

  create(title, address, email, phone, regions, minimum_delivery_cost, delivery_cost_per_kilometer, multiple_station_delivery_cost, tariff_explanation, logo) {
    const formData: FormData = new FormData();
    formData.append('title', title); formData.append('address', address);
    formData.append('email', email); formData.append('phone', phone);
    if (regions != null) {
      for (let i = 0; i < regions.length; i++) {
        formData.append('regions[]', regions[i]);
      }
    }
    formData.append('minimum_delivery_cost', minimum_delivery_cost);
    formData.append('delivery_cost_per_kilometer', delivery_cost_per_kilometer);
    formData.append('multiple_station_delivery_cost', multiple_station_delivery_cost);
    formData.append('tariff_explanation', tariff_explanation); formData.append('logo', logo);

    return this._httpClient
      .post(`${environment.apiUrl}/api/create/company`, formData)
      .pipe(
        tap(() => {
          this._refreshCompany.next()
        })
      );
  }

  update(id, title, address, email, phone, regions, minimum_delivery_cost, delivery_cost_per_kilometer, multiple_station_delivery_cost, tariff_explanation, logo) {
    const formData: FormData = new FormData();
    formData.append('title', title); formData.append('address', address);
    formData.append('email', email); formData.append('phone', phone);
    if (regions != null) {
      for (let i = 0; i < regions.length; i++) {
        formData.append('regions[]', regions[i]);
      }
    }
    formData.append('minimum_delivery_cost', minimum_delivery_cost);
    formData.append('delivery_cost_per_kilometer', delivery_cost_per_kilometer);
    formData.append('multiple_station_delivery_cost', multiple_station_delivery_cost);
    formData.append('tariff_explanation', tariff_explanation);
    if (logo == undefined) {
      formData.append('logo', null);
    } else {
      formData.append('logo', logo);
    }

    return this._httpClient
      .post(`${environment.apiUrl}/api/update/company/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshCompany.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/company/${id}`)
      .pipe(
        tap(() => {
          this._refreshCompany.next()
        })
      );
  }
}
