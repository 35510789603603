<li class="nav-item nav-search">
  <!-- Search icon -->
  <a class="nav-link nav-link-search" (click)="toggleSearch()">
    <span [data-feather]="'search'" [class]="'ficon'"></span>
  </a>

  <!-- Search -->
  <div class="search-input" [class.open]="openSearchRef === true">
    <!-- Search Input -->
    <div class="search-input-icon"><span [data-feather]="'search'"></span></div>
    <input class="form-control input" type="text" placeholder="Saisir le titre d'un poste ou d'un produit.."
      tabindex="-1" data-search="search" (keyup)="searchUpdate($event)" [(ngModel)]="searchText" #openSearch /><button
      class="btn search-input-close p-0" (click)="toggleSearch()"><span [data-feather]="'x'"></span></button>

    <!-- Search List -->
    <ul class="search-list search-list-main" [perfectScrollbar] [class.show]="searchText !== ''" #pageList>
      <!-- Stations -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)">
          <h6 class="section-label mt-75 mb-0">Postes</h6>
        </a>
      </li>
      <ng-container *ngIf="(stations | filter: searchText:'title').length; else noResultsStations">
        <li class="auto-suggestion" *ngFor="let station of stations | filter: searchText:'title' | slice: 0:10"
          [ngClass]="{ current_item: i === activeIndex }">
          <a class="d-flex align-items-center justify-content-between w-100" (click)="toggleSearch()"
            routerLink="/order/{{station.service.id}}/station/{{station.id}}/products/all">
            <div class="d-flex">
              <div class="mr-75"><img src="{{ apiUrl }}/uploads/{{ station.logo }}" alt="png" height="32" /></div>
              <div class="search-data">
                <p class="search-data-title mb-0">{{ station.title }}</p>
                <small class="text-muted">{{ station.description }}</small>
              </div>
            </div>
          </a>
        </li>
      </ng-container>

      <!-- No results found -->
      <ng-template #noResultsStations>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>Aucun résultat trouvé.</span>
            </div>
          </a>
        </li>
      </ng-template>
      <!--/ Stations -->

      <!-- Categories -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)">
          <h6 class="section-label mt-75 mb-0">Catégories</h6>
        </a>
      </li>
      <ng-container *ngIf="(categories | filter: searchText:'title').length; else noResultsCategories">
        <li class="auto-suggestion" *ngFor="let category of categories | filter: searchText:'title' | slice: 0:10"
          [ngClass]="{ current_item: i === activeIndex }">
          <a class="d-flex align-items-center justify-content-between w-100" (click)="toggleSearch()"
            routerLink="/order/category/{{category.id}}/products">
            <div class="d-flex">
              <div class="mr-75"><img src="{{ apiUrl }}/uploads/{{ category.image }}" alt="png" height="32" /></div>
              <div class="search-data">
                <p class="search-data-title mb-0">{{ category.title }}</p>
                <small class="text-muted">{{ category.service.title }}</small>
              </div>
            </div>
          </a>
        </li>
      </ng-container>
      <!-- No results found -->
      <ng-template #noResultsCategories>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>Aucun résultat trouvé.</span>
            </div>
          </a>
        </li>
      </ng-template>
      <!--/ Categories -->

      <!-- Products -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)">
          <h6 class="section-label mt-75 mb-0">Produits</h6>
        </a>
      </li>
      <ng-container *ngIf="(products | filter: searchText:'title').length; else noResultsProducts">
        <li class="auto-suggestion" *ngFor="let product of products | filter: searchText:'title' | slice: 0:10"
          [ngClass]="{ current_item: i === activeIndex }">
          <a class="d-flex align-items-center justify-content-between w-100" (click)="toggleSearch()"
            routerLink="/order/product/{{product.id}}">
            <div class="d-flex">
              <div class="mr-75">
                <img src="{{ apiUrl }}/uploads/{{ product.image ? product.image:product.category.image }}" alt="png"
                  height="32" />
              </div>
              <div class="search-data">
                <p class="search-data-title mb-0">{{ product.title }}</p>
                <small class="text-muted">{{ product.category.title }}</small>
              </div>
            </div>
            <small class="search-data-size mr-50 text-center text-muted">
              <img src="{{ apiUrl }}/uploads/{{ product.station.logo }}" height="20" />
              {{ product.station.title }}
              <div class="font-weight-bolder text-danger">{{product.price}} Dt</div>
            </small>
          </a>
        </li>
      </ng-container>
      <!-- No results found -->
      <ng-template #noResultsProducts>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>Aucun résultat trouvé.</span>
            </div>
          </a>
        </li>
      </ng-template>
      <!--/ Products -->
    </ul>
    <!--/ Search List -->
  </div>
</li>