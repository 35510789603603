<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; 2023-{{ year }}
    <a class="ml-25" href="https://www.softsquareit.com/" target="_blank">SoftSquare IT</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<!-- <app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top> -->

<!-- Buynow Button
<div class="buy-now">
  <a href="https://www.softsquareit.com/" target="_blank" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow">Buy
    Now</a>
</div>-->