import { Component, OnInit, Input } from '@angular/core';

import { Subject } from 'rxjs';

import { EcommerceService } from 'app/main/apps/ecommerce/ecommerce.service';
import { CartService } from 'app/service/cart/cart.service';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-navbar-cart',
  templateUrl: './navbar-cart.component.html'
})
export class NavbarCartComponent implements OnInit {
  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  apiUrl = environment.apiUrl
  total = 0

  @Input() company;

  show
  // Public
  public stations
  public products = [];
  public cartList = [];
  public cartListLength;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {EcommerceService} _ecommerceService
   */
  constructor(public _ecommerceService: EcommerceService,
    private authenticationService: AuthenticationService,
    private cartService: CartService,
    public _router: Router,
    private route: ActivatedRoute) {
    this._unsubscribeAll = new Subject();
  }

  /**
   * Remove From Cart
   *
   * @param product
   */
  removeFromCart(product) {
    if (product.isInCart === true) {
      this._ecommerceService.removeFromCart(product.id).then(res => {
        product.isInCart = false;
      });
    }
  }

  removeProduct(product) {
    this.cartService.remove(product)
  }

  handleQuantity(event, product) {
    let p = this.products.find(item => item.productId === product.productId)
    p.quantity = event
    this.cartService.syncProducts()
    this.calculateTotal()
  }

  calculateTotal() {
    this.total = 0
    this.cartService.products.forEach(product => {
      this.total += product.totalPrice * product.quantity
    });
  }

  ngOnInit(): void {
    this.cartService.refreshProducts.subscribe(() => {
      this.stations = this.cartService.reorderProducts(this.cartService.products)
      this.products = this.cartService.products
      this.cartListLength = this.cartService.products.length
      this.calculateTotal()
    })
    this.stations = this.cartService.reorderProducts(this.cartService.products)
    this.products = this.cartService.products
    this.cartListLength = this.cartService.products.length
    this.calculateTotal()
  }
}
