import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private _products: {
    productId: string, productTitle: string,
    productDescription: string, unitPrice: number,
    quantity: number, totalPrice: number
  }[] = [];

  private storageSub = new Subject<any>();

  constructor() {
    this._products = JSON.parse(localStorage.getItem('products') || '[]'); // get the data at lunch 
  }

  get refreshProducts() {
    return this.storageSub;
  }

  remove(item) {
    const index = this._products.indexOf(item);
    this._products.splice(index, 1);
    this.syncProducts();
  }

  add(item) {
    this._products.push(item);
    this.syncProducts();
  }

  get length(): number {
    return this._products.length
  }

  get products() {
    return this._products.slice(0)
  }

  syncProducts() {
    localStorage.setItem('products', JSON.stringify(this._products));
    this.storageSub.next(this._products);
  }

  emptyCart() {
    this._products = [];
    this.syncProducts();
  }

  reorderProducts(products) {
    let stations = []
    products.forEach(element => {
      if (!stations.map(item => item.id).includes(element.stationId)) {
        stations.push({
          id: element.stationId, title: element.stationTitle,
          logo: element.stationLogo,
          products: [{
            productId: element.productId, productTitle: element.productTitle,
            productDescription: element.productDescription, productComment: element.productComment, quantity: element.quantity,
            unitPrice: element.unitPrice, totalPrice: element.totalPrice, supplements: element.supplements
          }]
        })
      } else {
        let index = stations.map(item => item.id).indexOf(element.stationId)
        stations[index].products.push({
          productId: element.productId, productTitle: element.productTitle,
          productDescription: element.productDescription, productComment: element.productComment, quantity: element.quantity,
          unitPrice: element.unitPrice, totalPrice: element.totalPrice, supplements: element.supplements
        })
      }
    });
    return stations
  }
}
