import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private _refreshCategory = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshCategory() {
    return this._refreshCategory;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/list/category`);
  }

  getOptimizedList() {
    return this._httpClient.get(`${environment.apiUrl}/api/optimized_list/category`);
  }

  getCategoryByService(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/category_by_service/${id}`);
  }

  getCategory(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/category/${id}`);
  }

  create(station, title, description, banner, icon, image, service) {
    const formData: FormData = new FormData();
    formData.append('station', station);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('banner', banner);
    formData.append('icon', icon);
    formData.append('image', image);
    formData.append('service', service);

    return this._httpClient
      .post(`${environment.apiUrl}/api/create/category`, formData)
      .pipe(
        tap(() => {
          this._refreshCategory.next()
        })
      );
  }

  update(id, title, description, banner, icon, image, service) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('banner', banner);
    formData.append('icon', icon);
    formData.append('image', image);
    formData.append('service', service);

    return this._httpClient
      .post(`${environment.apiUrl}/api/update/category/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshCategory.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/category/${id}`)
      .pipe(
        tap(() => {
          this._refreshCategory.next()
        })
      );
  }

}
