<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
        <!-- Login v1 -->
        <div class="card mb-0">
            <div class="card-body">
                <a href="javascript:void(0);" class="brand-logo">
                    <h2 class="brand-text text-primary ml-1" *ngIf="!company">{{ coreConfig.app.appName }}</h2>
                    <img *ngIf="company" src="{{ apiUrl }}/uploads/{{ company.logo }}" alt="brand-logo" height="28" />
                    <h2 class="brand-text text-primary ml-1" *ngIf="company">{{company.title}}</h2>
                </a>

                <p class="card-text mb-2">Connectez-vous à votre compte et commencez l'aventure!</p>

                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                    <div class="alert-body">
                        <p>{{ error.message }}</p>
                    </div>
                </ngb-alert>

                <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="login-email" class="form-label">Identifiant</label>
                        <input type="text" formControlName="email" class="form-control" placeholder="john@exemple.com"
                            aria-describedby="login-email" autofocus
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">E-mail est requis</div>
                            <!-- <div *ngIf="f.email.errors.email">L'adresse e-mail doit être valide</div> -->
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="d-flex justify-content-between">
                            <label for="login-password">Mot de passe</label>
                            <!-- <a routerLink="/pages/authentication/forgot-password-v1">
                            <small>Mot de passe oublié?</small>
                            </a> -->
                        </div>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password"
                                class="form-control form-control-merge" placeholder="·········"
                                aria-describedby="login-password"
                                [ngClass]="{ 'is-invalid error': submitted && f.password.errors }" />

                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                        'icon-eye-off': passwordTextType,
                        'icon-eye': !passwordTextType
                      }" (click)="togglePasswordTextType()"></i></span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback"
                            [ngClass]="{ 'd-block': submitted && f.password.errors }">
                            <div *ngIf="f.password.errors.required">Mot de passe est requis</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" type="checkbox" id="remember-me" />
                            <label class="custom-control-label" for="remember-me"> Me souvenir</label>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-block" type="submit" [disabled]="loadingLogin">
                        <span *ngIf="loadingLogin" class="spinner-border spinner-border-sm mr-1"></span>
                        S'identifier
                    </button>
                </form>

                <p class="text-center mt-2" *ngIf="company">
                    <a routerLink="/order/service">
                        <span>&nbsp;Passer une commande</span>
                    </a>
                </p>
            </div>
        </div>
        <!-- /Login v1 -->
    </div>
</div>