<li ngbDropdown class="nav-item dropdown-cart mr-25">
  <div *ngIf="products.length > 0 && (!currentUser || currentUser.role === 'customer')"
    class="navbar-container fixed-bottom" ngbDropdownToggle id="navbarCartDropdown">
    <div class="justify-content-center text-center">
      <button type="submit" class="btn btn-success btn-sm-block" rippleEffect>
        <i data-feather="shopping-cart" class="mr-25"></i>
        <span class="add-to-cart">Mon panier ({{total}} Dt)</span>
      </button>
    </div>
  </div>
  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarCartDropdown">
    <span [data-feather]="'shopping-cart'" [class]="'ficon'"></span>
    <span class="badge badge-pill badge-primary badge-up cart-item-count">
      {{ cartListLength }}
    </span>
  </a>
  <ul ngbDropdownMenu aria-labelledby="navbarCartDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <!-- Cart header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Mon panier</h4>
        <div class="badge badge-pill badge-light-primary">{{ cartListLength }} Produits</div>
      </div>
    </li>
    <!--/ Cart header -->

    <!-- Cart content -->
    <li class="scrollable-container media-list" [perfectScrollbar] *ngIf="products.length">
      <ng-container *ngFor="let station of stations">
        <h6 class="ml-1 mt-1">
          <img src="{{ apiUrl }}/uploads/{{ station.logo }}" height="25" />
          {{ station.title }}
        </h6>
        <div class="media align-items-center" *ngFor="let product of station.products">
          <div class="d-block rounded mr-1">
            <button class="btn p-0" (click)="removeProduct(product)">
              <i data-feather="trash-2" [size]="15" class="text-danger"></i>
            </button>
          </div>
          <div class="media-body">
            <div class="media-heading">
              <h6 class="cart-item-title">
                <a class="text-body">{{product.productTitle}}
                  <span role="button" *ngIf="product.productComment" [ngbTooltip]="product.productComment"
                    triggers="click:blur">
                    <span data-feather="alert-circle" class="text-danger"></span></span>
                </a>
              </h6>
              <div *ngFor="let supplement of product.supplements">
                <small>{{ supplement.supplementTitle }}:
                  <span *ngFor="let option of supplement.chosenOptions; let last =last">
                    {{option.optionTitle}}
                    <span class="badge badge-pill badge-light-secondary" *ngIf="option.optionPrice > 0">
                      {{option.optionPrice}} Dt<span *ngIf="option.optionQuantity && option.optionQuantity > 1">
                        (x {{option.optionQuantity}}) </span>
                    </span>
                    <span *ngIf="!last">,</span>
                  </span>
                </small>
              </div>
            </div>
            <div class="cart-item-qty">
              <div class="input-group touchspin-cart">
                <core-touchspin [numberValue]="product.quantity" [stepValue]="1" [minValue]="1" [maxValue]="10"
                  (onChange)="handleQuantity($event, product)"></core-touchspin>
              </div>
              <h5 class="cart-item-price">{{ product.totalPrice * product.quantity }} Dt</h5>
            </div>
          </div>
        </div>
      </ng-container>
    </li>
    <!--/ Cart content -->

    <!-- Cart footer -->
    <li class="dropdown-menu-footer" *ngIf="company">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">Total:</h6>
        <h6 class="text-primary font-weight-bolder mb-0">{{total.toFixed(2)}} Dt</h6>
      </div>
      <button class="btn btn-success btn-block" [disabled]="products.length == 0" ngbDropdownToggle
        routerLink="/order/confirm">Confirmer</button>
      <p class="text-center mt-2" *ngIf="!currentUser">
        <a routerLink="/login/{{company.alias}}">
          <span>&nbsp;S'identifier</span>
        </a>
      </p>
    </li>
    <!--/ Cart footer -->
  </ul>
</li>