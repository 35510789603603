import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public coreConfig

  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient,
    private _toastrService: ToastrService,
    private _router: Router,
    private _coreConfigService: CoreConfigService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }
  get isAgent() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Agent;
  }
  get isDeliveryman() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Deliveryman;
  }
  get isCustomer() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Customer;
  }

  login(username: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/api/auth/login`, { username, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  refreshToken(refresh_token) {
    return this._http
      .post<any>(`${environment.apiUrl}/api/token/refresh`, { refresh_token })
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }

  redirectUser(role) {
    this.coreConfig = this._coreConfigService.config.source._value

    switch (role) {
      case 'super_admin': {
        this.coreConfig.app.appLogoImage = this.currentUserValue.logo
        this._router.navigate(['admin/governorate']);
        break;
      }
      case 'station_admin': {
        this.coreConfig.app.appLogoImage = this.currentUserValue.station_logo
        this._router.navigate(['station/agent']);
        break;
      }
      case 'company_admin': {
        this.coreConfig.app.appLogoImage = this.currentUserValue.company_logo
        this._coreConfigService.setCompany(this.currentUserValue.company_alias)
        this._router.navigate(['company/order']);
        break;
      }
      case 'deliveryman': {
        this.coreConfig.app.appLogoImage = this.currentUserValue.company_logo
        this._router.navigate(['company/deliveryman_interface']);
        break;
      }
      case 'customer': {
        this._router.navigate(['customer/order']);
        break;
      }
    }
  }
}
