import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Administrator
  {
    id: 'apps',
    type: 'section',
    title: 'Admin',
    role: ['super_admin'],
    icon: 'package',
    children: [
      {
        id: 'governorate',
        title: 'Governorat',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['super_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'map-pin',
        url: 'admin/governorate'
      },
      {
        id: 'company',
        title: 'Agence',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['super_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'home',
        url: 'admin/company'
      },
      {
        id: 'service',
        title: 'Service',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['super_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'square',
        url: 'admin/service'
      },
      {
        id: 'category',
        title: 'Catégorie',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['super_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'grid',
        url: 'admin/category'
      },
      {
        id: 'post',
        title: 'Poste',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['super_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'home',
        url: 'admin/station'
      },
      {
        id: 'parameter',
        title: 'Parameter',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['super_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'sliders',
        url: 'admin/parameter'
      },
    ]
  },
  // Agent
  {
    id: 'apps',
    type: 'section',
    title: 'Agent',
    role: ['station_admin'],
    icon: 'package',
    children: [
      {
        id: 'agents',
        title: 'Agent',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['station_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'users',
        url: 'station/agent'
      },
      {
        id: 'product',
        title: 'Produit',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['station_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'shopping-bag',
        url: 'station/product'
      },
    ]
  },
  // Deliveryman
  {
    id: 'apps',
    type: 'section',
    title: 'Livreur',
    role: ['company_admin'],
    icon: 'package',
    children: [
      {
        id: 'order',
        title: 'Tableau de bord',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['company_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'home',
        url: 'company/order'
      },
      {
        id: 'deliveryman',
        title: 'Livreur',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['company_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'users',
        url: 'company/deliveryman'
      },
      {
        id: 'order',
        title: 'Commande',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['company_admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'shopping-cart',
        url: 'company/order-datatable'
      },
    ]
  },
  {
    id: 'apps',
    type: 'section',
    title: 'Livreur',
    role: ['deliveryman'],
    icon: 'package',
    children: [
      {
        id: 'myOrders',
        title: 'Commande',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['deliveryman'], //? To set multiple role: ['Admin', 'Client']
        icon: 'shopping-cart',
        url: 'company/deliveryman_interface'
      },
    ]
  },
  {
    id: 'post',
    title: 'Poste',
    //translate: 'MENU.DASHBOARD.ANALYTICS',
    type: 'item',
    role: ['poste', 'supplement'], //? To set multiple role: ['Admin', 'Client']
    icon: 'home',
    url: 'company/station'
  },
  {
    id: 'apps',
    type: 'section',
    title: 'Client',
    role: ['customer'],
    icon: 'package',
    children: [
      {
        id: 'order',
        title: 'Commande',
        //translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['customer'], //? To set multiple role: ['Admin', 'Client']
        icon: 'shopping-cart',
        url: 'customer/order'
      },
    ]
  }
  // {
  //   id: 'apps',
  //   type: 'section',
  //   title: 'Client',
  //   role: ['customer'],
  //   icon: 'package',
  //   children: [
  //     {
  //       id: 'order',
  //       title: 'Commande',
  //       //translate: 'MENU.DASHBOARD.ANALYTICS',
  //       type: 'item',
  //       role: ['customer'], //? To set multiple role: ['Admin', 'Client']
  //       icon: 'shopping-cart',
  //       url: 'customer/order'
  //     },
  //   ]
  // }

];
