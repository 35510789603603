import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StationService {

  private _refreshStation = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshStation() {
    return this._refreshStation;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/list/station`);
  }

  getOptimizedList() {
    return this._httpClient.get(`${environment.apiUrl}/api/optimized_list/station`);
  }

  getStation(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/station/${id}`);
  }

  getOptimizedStation(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/optimized_station/${id}`);
  }

  create(title, description, address, email, phone, region, category, workDays, startTime, endTime, logo, banner) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('address', address);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('region', region);
    if (category != null) {
      for (let i = 0; i < category.length; i++) {
        formData.append('category[]', category[i]);
      }
    }
    if (workDays != null) {
      for (let i = 0; i < workDays.length; i++) {
        formData.append('workDays[]', workDays[i]);
      }
    }
    formData.append('startTime', JSON.stringify(startTime));
    formData.append('endTime', JSON.stringify(endTime));
    formData.append('logo', logo);
    formData.append('banner', banner);

    return this._httpClient
      .post(`${environment.apiUrl}/api/create/station`, formData)
      .pipe(
        tap(() => {
          this._refreshStation.next()
        })
      );
  }

  update(id, title, description, address, email, phone, region, category, workDays, startTime, endTime, logo, banner) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('address', address);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('region', region);
    if (category != null) {
      for (let i = 0; i < category.length; i++) {
        formData.append('category[]', category[i]);
      }
    }
    if (workDays != null) {
      for (let i = 0; i < workDays.length; i++) {
        formData.append('workDays[]', workDays[i]);
      }
    }
    formData.append('startTime', JSON.stringify(startTime));
    formData.append('endTime', JSON.stringify(endTime));
    formData.append('logo', logo);
    formData.append('banner', banner);

    return this._httpClient
      .post(`${environment.apiUrl}/api/update/station/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshStation.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/station/${id}`)
      .pipe(
        tap(() => {
          this._refreshStation.next()
        })
      );
  }

}
