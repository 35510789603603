import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([401, 403].indexOf(err.status) !== -1) {
          if (this._authenticationService.currentUser) {
            let currentUser = Object.freeze(this._authenticationService.currentUserValue);
            this._authenticationService.refreshToken(currentUser.refresh_token).subscribe({
              next: data => {
                localStorage.setItem('currentUser', JSON.stringify(data));
                window.location.reload();
              },
              error: err => {
                this._authenticationService.logout();
                console.log(err);
              }
            })
          }
        }
        // throwError
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
