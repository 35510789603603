import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private _refreshProduct = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshProduct() {
    return this._refreshProduct;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/api/list/product`);
  }

  getOptimizedList() {
    return this._httpClient.get(`${environment.apiUrl}/api/optimized_list/product`);
  }

  getProduct(id) {
    return this._httpClient.get(`${environment.apiUrl}/api/product/${id}`);
  }

  getByStation(station_id) {
    return this._httpClient.get(`${environment.apiUrl}/api/product_by_station/${station_id}`);
  }

  getByStationAndCategory(station_id, category_id) {
    return this._httpClient.get(`${environment.apiUrl}/api/product_by_station_&_category/${station_id}/${category_id}`);
  }

  create(station, title, description, image, images, category, supplements, price) {
    const formData: FormData = new FormData();
    formData.append('station', station);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('image', image);
    if (images != null) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images[]', images[i]);
      }
    }
    formData.append('category', category);
    if (supplements != null) {
      for (let i = 0; i < supplements.length; i++) {
        formData.append('supplements[]', supplements[i]);
      }
    }
    formData.append('price', price);

    return this._httpClient
      .post(`${environment.apiUrl}/api/create/product`, formData)
      .pipe(
        tap(() => {
          this._refreshProduct.next()
        })
      );
  }

  updateStatut(id, statut) {
    return this._httpClient
      .put(`${environment.apiUrl}/api/statut/product/${id}`, { statut } )
      .pipe(
        tap(() => {
          this._refreshProduct.next()
        })
      );
  }

  update(id, title, description, image, images, category, supplements, price) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('image', image);
    if (images != null) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images[]', images[i]);
      }
    }
    formData.append('category', category);
    if (supplements != null) {
      for (let i = 0; i < supplements.length; i++) {
        formData.append('supplements[]', supplements[i]);
      }
    }
    formData.append('price', price);

    return this._httpClient
      .post(`${environment.apiUrl}/api/update/product/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshProduct.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/api/delete/product/${id}`)
      .pipe(
        tap(() => {
          this._refreshProduct.next()
        })
      );
  }

}
